import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { SelectboxComponent } from './selectbox/selectbox.component';
import { NotifyModalComponent } from './notify-modal/notify-modal.component';
import { SearchboxComponent } from './searchbox/searchbox.component';
import { CollapseListComponent } from './collapse-list/collapse-list.component';
import { ConfirmInputModalComponent } from './confirm-input-modal/confirm-input-modal.component';
import { ChangeAvatarModalComponent } from './change-avatar-modal/change-avatar-modal.component';

import { SelectboxWSearchComponent } from './selectbox-w-search/selectbox-w-search.component';
import { SelectboxWSearchUserComponent } from './selectbox-w-search-user/selectbox-w-search-user.component';
import { SearchableSelectboxComponent } from './searchable-selectbox/searchable-selectbox.component';

import { RoleInfoModalComponent } from './role-info-modal/role-info-modal.component';
import { ConfirmSyncModalComponent } from './confirm-sync-modal/confirm-sync-modal.component';
import { SpinnerComponent } from '@app/shared/spinner/spinner.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { RequiredNotifyComponent } from './required-notify/required-notify.component';
import { AttachedFileModalComponent } from './attached-file-modal/attached-file-modal.component';
import { SelectboxMultiChoiceComponent } from './selectbox-multi-choice/selectbox-multi-choice.component';
import { ViewAttachFileModalComponent } from './view-attach-file-modal/view-attach-file-modal.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { SelectboxMultiChoiceTableComponent } from './selectbox-multi-choice-table/selectbox-multi-choice-table.component';
import { FileUploadModalComponent } from './file-upload-modal/file-upload-modal.component';
import { DynamicSelectboxSingleChoiceComponent } from './dynamic-form/selectbox-single-choice/selectbox-single-choice.component';
import { DynamicSelectboxMultiChoiceComponent } from './dynamic-form/selectbox-multi-choice/selectbox-multi-choice.component';
import { TagsInputVComponent } from './tags-input-v/tags-input-v.component';
import { FormsModule } from '@angular/forms';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead'
import { DynamicSelectboxWSearchComponent } from './dynamic-form/selectbox-w-search/selectbox-w-search.component';
import { SelectboxTableComponent } from './selectbox-table/selectbox-table.component';

@NgModule({
  declarations: [
    ConfirmModalComponent, 
    SelectboxComponent, 
    NotifyModalComponent, 
    SearchboxComponent, 
    CollapseListComponent, 
    ConfirmInputModalComponent, 
    ChangeAvatarModalComponent, 
    SelectboxWSearchComponent, 
    SelectboxWSearchUserComponent,
    SearchableSelectboxComponent,
    RoleInfoModalComponent,
    ConfirmSyncModalComponent,
    SpinnerComponent,
    FileUploadComponent,
    RequiredNotifyComponent,
    AttachedFileModalComponent,
    SelectboxMultiChoiceComponent,
    ViewAttachFileModalComponent,
    SelectboxMultiChoiceTableComponent,
    FileUploadModalComponent,
    DynamicSelectboxSingleChoiceComponent,
    DynamicSelectboxMultiChoiceComponent,
    TagsInputVComponent,
    DynamicSelectboxWSearchComponent,
    SelectboxTableComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgxDocViewerModule,
    FormsModule,
    TypeaheadModule.forRoot()
  ],
  exports: [
    ConfirmModalComponent,
    SelectboxComponent,
    NotifyModalComponent,
    SearchboxComponent, 
    SelectboxWSearchComponent,
    SelectboxWSearchUserComponent,
    SearchableSelectboxComponent,
    CollapseListComponent,
    ConfirmInputModalComponent,
    ChangeAvatarModalComponent,
    RoleInfoModalComponent,
    ConfirmSyncModalComponent,
    SpinnerComponent,
    FileUploadComponent,
    RequiredNotifyComponent,
    AttachedFileModalComponent,
    SelectboxMultiChoiceComponent,
    ViewAttachFileModalComponent,
    SelectboxMultiChoiceTableComponent,
    FileUploadModalComponent,
    DynamicSelectboxSingleChoiceComponent,
    DynamicSelectboxMultiChoiceComponent,
    TagsInputVComponent,
    DynamicSelectboxWSearchComponent,
    SelectboxTableComponent
  ]
})
export class ReusedModule { }
