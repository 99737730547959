
<nav class="sidebar sidebar-offcanvas" id="sidebar">
    <div class = "navbar" style="padding: 2.5rem 0; border-bottom: 1px #eee solid;">
        <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
            <a class="navbar-brand brand-logo" routerLink="{{homeUrl}}"><img src="assets/images/logo/logo-sct-bg-name.jpg" alt="" class="img-fluid"/></a>
            <a class="navbar-brand brand-logo-mini" routerLink="{{homeUrl}}"><img src="assets/images/logo/logo-sct-new.png" alt="" class="img-fluid"/></a>
        </div>
    </div>
    <ul class="nav">
        <li class="nav-item nav-profile" ngbDropdown style="border-bottom: 1px #eee solid; padding: 0;">
            <a class="nav-link cursor-pointer pt-2 pb-2" >
                <div class="nav-profile-image pl-2" ngbDropdownToggle>
                    <img *ngIf="checkImg()" src="{{avatarUrl}}" alt="profile">
                    <span *ngIf="!checkImg()" style="height: 44px; width: 44px;" class="avatar-title bg-primary">{{userLastname.charAt(0)}}{{userFirstname.charAt(0)}}</span>
                    <span *ngIf="totalNotifications && totalNotifications > 0" title="{{totalNotifications}}{{'notification-center-new_footer' | translate}}" class="badge notify-badge header-badge rounded-pill ml-2">{{formatNumberOfNotification(totalNotifications)}}</span>
                </div>
                <form class="app-search nav-profile-text">
                    <div class="position-relative">
                        <input type="text" id="searchMenuInput" class="form-control" placeholder="{{'global_search-placeholder' | translate}}">
                        <span class="mdi mdi-magnify"></span>
                    </div>
                </form>
                <button class="btn btn-toggle-sidebar nav-profile-badge p-2" title="{{'global_collapse' | translate}}" (click)="hiddenSidebar()">
                    <em class="mdi mdi-menu"></em>
                </button> 
            </a>
            <div class="dropdown-menu navbar-dropdown dropdown-config" ngbDropdownMenu aria-labelledby="profileDropdown">
                <a class="dropdown-item" [routerLink]="['/user']">
                  <em class="mdi mdi-account-circle-outline mr-2 text-success"></em>
                  {{'auth_user-detail' | translate}}
                </a>
                <a class="dropdown-item" [routerLink]="['/user/update-information']">
                    <em class="mdi mdi-account-settings mr-2 text-primary"></em>
                    {{'auth_user-settings' | translate}}
                </a>
                <a class="dropdown-item" [routerLink]="['/user/change-password']">
                    <em class="mdi mdi-shield-key-outline mr-2" style="color: darkorange;"></em>
                    {{'auth_user-change_pass' | translate}}
                </a>
                <a class="dropdown-item" [routerLink]="['/user/schedule/work']" *ngIf="!isEnt">
                    <em class="mdi mdi-calendar-month mr-2 text-info"></em>
                    {{'auth_user-schedule' | translate}}
                </a>
                <a class="dropdown-item" (click)="openNotificationCenter()" *ngIf="!isEnt">
                    <em *ngIf="totalNotifications && totalNotifications > 0" class="mdi mdi-bell-ring mr-2 text-warning"></em>
                    <em *ngIf="totalNotifications == 0" class="mdi mdi-bell mr-2 text-secondary"></em>
                    {{'auth_user-notification' | translate}}
                    <span *ngIf="totalNotifications && totalNotifications > 0" title="{{totalNotifications}}{{'notification-center-new_footer' | translate}}" class="badge notify-badge rounded-pill ml-2">{{formatNumberOfNotification(totalNotifications)}}</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="submitLogout()">
                  <em class="mdi mdi-logout mr-2 text-danger"></em>
                  {{'auth_logout' | translate}}
                </a>
            </div>   
        </li>
        <perfect-scrollbar [hidden]="isCollapsedSidebar" class="scrollbar-config" [config]="scrollbarConfigSidebar">
            <!-- <ng-container *ngIf="!isEnt"> -->
            <ng-container>
                <div *ngFor="let data of showMenuData">
                    <div *ngIf="data.child.length == 0">
                        <li class="nav-item" *ngIf="isAdmin || (!isAdmin && !data.isAdmin)" [ngClass]="{'active': activeUrl.includes(data.routerLink)}">
                            <a class="nav-link" routerLink="{{data.routerLink}}">
                                <span class="menu-title">{{data.name | translate}}</span>
                                <em class="mdi {{data.icon}} menu-icon"></em>
                            </a>
                        </li>
                    </div>
                    <div *ngIf="data.child.length > 0">
                        <li class="nav-item" *ngIf="isAdmin || (!isAdmin && !data.isAdmin)" [ngClass]="{'active': activeUrl.includes(data.routerLink)}">
                            <a class="nav-link" (click)="data.isExpand = !data.isExpand" [attr.aria-expanded]="data.isExpand" attr.aria-controls="{{data.icon}}">
                                <span class="menu-title overflow-title">{{data.name | translate}}</span>
                                <em class="menu-arrow"></em>
                                <em class="mdi {{data.icon}} menu-icon"></em>
                            </a>
                            <div class="collapse" id="{{data.icon}}" [ngbCollapse]="!data.isExpand">
                                <ng-container *ngFor="let child of data.child">
                                    <ul class="nav flex-column sub-menu" *ngIf="isAdmin || (!isAdmin && (!child.isCheckPerm || (child.isCheckPerm && checkPermission(child.isCheckPerm.objClass))))">
                                        <li class="nav-item"> 
                                            <a class="nav-link overflow-link" title="{{child.name | translate}}" routerLink="{{child.routerLink}}" [ngClass]="{'active': activeUrl.includes(child.routerLink)}">{{child.name | translate}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </li>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="isEnt">
                <li class="nav-item" [ngClass]="{'active': activeUrl==sidebarEntData.routerLink)}">
                    <a class="nav-link" routerLink="{{sidebarEntData.routerLink}}">
                        <span class="menu-title">{{sidebarEntData.name | translate}}</span>
                        <em class="mdi {{sidebarEntData.icon}} menu-icon"></em>
                    </a>
                </li>
            </ng-container> -->
        </perfect-scrollbar>
        <div [hidden]="!isCollapsedSidebar" style="height: calc(100vh - 165px);">
            <ng-container *ngIf="!isEnt">
                <div *ngFor="let data of showMenuData">
                    <div *ngIf="data.child.length == 0">
                        <li class="nav-item" *ngIf="isAdmin || (!isAdmin && !data.isAdmin)" [ngClass]="{'active': activeUrl.includes(data.routerLink)}">
                            <a class="nav-link" routerLink="{{data.routerLink}}">
                                <span class="menu-title">{{data.name | translate}}</span>
                                <em class="mdi {{data.icon}} menu-icon"></em>
                            </a>
                        </li>
                    </div>
                    <div *ngIf="data.child.length > 0">
                        <li class="nav-item" *ngIf="isAdmin || (!isAdmin && !data.isAdmin)" 
                            [ngClass]="{'active': activeUrl.includes(data.routerLink), 'hover-open': hoverName == data.name}"
                            (mouseover)="hoverName=data.name" (mouseout)="hoverName=''"
                        >
                            <a class="nav-link" (click)="data.isExpand = !data.isExpand" [attr.aria-expanded]="data.isExpand" attr.aria-controls="{{data.icon}}">
                                <span class="menu-title overflow-title">{{data.name | translate}}</span>
                                <em class="menu-arrow"></em>
                                <em class="mdi {{data.icon}} menu-icon"></em>
                            </a>
                            <div class="collapse" id="{{data.icon}}" [ngbCollapse]="!data.isExpand">
                                <ng-container *ngFor="let child of data.child">
                                    <ul class="nav flex-column sub-menu" *ngIf="isAdmin || (!isAdmin && (!child.isCheckPerm || (child.isCheckPerm && checkPermission(child.isCheckPerm.objClass))))">
                                        <li class="nav-item"> 
                                            <a class="nav-link overflow-link" title="{{child.name | translate}}" routerLink="{{child.routerLink}}" [ngClass]="{'active': activeUrl.includes(child.routerLink)}">{{child.name | translate}}</a>
                                        </li>
                                    </ul>
                                </ng-container>
                            </div>
                        </li>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="isEnt">
                <li class="nav-item" [ngClass]="{'active': activeUrl.includes(sidebarEntData.routerLink)}">
                    <a class="nav-link" routerLink="{{sidebarEntData.routerLink}}">
                        <span class="menu-title">{{sidebarEntData.name | translate}}</span>
                        <em class="mdi {{sidebarEntData.icon}} menu-icon"></em>
                    </a>
                </li>
            </ng-container>
            <div class="row introduced-title">
                <div class="col-12 pt-0 pb-0 text-right">
                    <p>{{appVersion}}</p>
                </div>
            </div>
        </div>
    </ul>
    <div [hidden]="isCollapsedSidebar" class="row introduced-title">
        <div class="col-12 pt-0 pb-0 text-right">
            <p>{{appVersion}}</p>
        </div>
    </div>
</nav>
<notify-modal [type]="type" [content]="content" [title]="title"></notify-modal>
<notification-center (onRead)="onReadNotification()"></notification-center>