export const Constant = {
    AUTH_LOGIN: 'login',
    AUTH_RESET_PASS: 'resetPassword',
    AUTH_LOGOUT: 'logout?uid={user_id}&deid={device_id}',
    AUTH_TOKEN_VALIDATE: 'validateToken',
    AUTH_USER_PERMISSION: 'permission/byUserId',
    AUTH_REGISTER: 'register/enterprise',
    //BP PERMISSION FOR USER
    GET_BP_PERMISSON: 'PBBaseStepUser?sortBy=seqNum&sortOrder=asc&userId={userId}',
    GET_BP_PERMISSON_BY_BASE_STEP: 'PBBaseStepUser?sortBy=seqNum&sortOrder=asc&baseStepId={baseStepId}',
    GET_LIST_PAGING_BP_PERMISSON: 'PBBaseStepUser/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&userId={userId}&baseStepId={baseStepId}&departmentId={departmentId}',
    CRUD_BP_PERMISSON: 'PBBaseStepUser/{id}',
    //PROFILE USER
    USER_DETAIL: 'user/{user_id}',
    USER_UPDATE: 'user/{user_id}',
    USER_CHANGE_PASS: 'user/changePassword/{user_id}',
    //UPLOAD
    UPLOAD: 'file/upload',
    UPLOAD_NEW: 'File/uploadNew',
    //ROLE
    ROLE_GET_LIST: "role",
    //DEPARTMENT
    DEPARTMENT_GET_LIST: "Department",
    //POSITION
    POSITION_GET_LIST: "position",
    //enterpríe
    REGISTER_ENT:"Enterprise",
    UPDATE_ENT:"Enterprise/{id}",

    //SYSTEM MANAGEMENT USER
    SM_USER_CREATE: 'user',
    SM_ENTERPRISE_USER_CREATE: 'user/enterprise',
    SM_USER_DELETE: 'user/{user_id}',
    SM_USER_UPDATE: 'user/{user_id}',
    SM_USER_DETAIL: 'user/{user_id}',
    SM_USER_GET_LIST: 'user?searchKey={searchKey}',
    SM_USER_GET_LIST_BY_DEPARTMENT_ID: 'user?searchKey={searchKey}&departmentId={departmentId}',
    SM_USER_LIST_PAGING: 'user/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_USER_CHANGE_PASS: 'user/changePassword/{user_id}',
    SM_USER_CHANGE_ROLE: 'user/changeRole/{user_id}',
    SM_USER_CHANGE_STATUS: 'user/changeStatus/{user_id}',
    SM_USER_RESET_PASS: 'user/resetPassword/{user_id}',
    //SYSTEM MANAGEMENT USER
    SM_ENT_ACCEPT: 'enterprise/accept',
    SM_ENT_CRUD: 'enterprise/{id}',
    SM_ENT_GET_LIST: 'enterprise?searchKey={searchKey}',
    SM_ENT_LIST_PAGING: 'enterprise/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ENT_USER_GET_LIST: 'user/enterprise?searchKey={searchKey}',
    SM_ENT_USER_GET_LIST_PAGING: 'user/enterprise/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    //SYSTEM MANAGEMENT MAIL
    SM_MAIL_CREATE: 'emailConfig',
    SM_MAIL_DELETE: 'emailConfig/{id}',
    SM_MAIL_UPDATE: 'emailConfig/{id}',
    SM_MAIL_DETAIL: 'emailConfig/{id}',
    SM_MAIL_GET_LIST: 'emailConfig?searchKey={searchKey}',
    SM_MAIL_LIST_PAGING: 'emailConfig/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_MAIL_ACTIVATE: 'emailConfig/select/{id}',
    //GROUP PERMISSION
    SM_GP_GET_LIST: 'permissionGroup?searchKey={searchKey}',
    SM_GP_LIST_PAGING: 'permissionGroup/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_GP_CREATE: 'permissionGroup',
    SM_GP_DELETE: 'permissionGroup/{id}',
    SM_GP_UPDATE: 'permissionGroup/{id}',
    SM_GP_DETAIL: 'permissionGroup/{id}',
    //PERMISSION OBJECT
    SM_PO_GET_LIST: 'permObject?searchKey={searchKey}',
    SM_PO_LIST_PAGING: 'permObject/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_PO_CREATE: 'permObject',
    SM_PO_DELETE: 'permObject/{id}',
    SM_PO_UPDATE: 'permObject/{id}',
    SM_PO_DETAIL: 'permObject/{id}',
    //OBJECT CLASS FOR PERMISSION
    OBJECT_CLASS_GET_LIST: 'objClass?searchKey={searchKey}&sortBy=funcModuleCode&funcModuleCodes={funcModuleCode}&hasPerms=1',
    OBJECT_CLASS_DETAIL: 'objClass/{id}',
    OBJECT_CLASS_UPDATE: 'objClass/{id}',
    OBJECT_CLASS_EXPORT_EXCEL: 'objClass/exportExcel',
    //FUNC MODULE
    FUNC_MODULE_GET_LIST: 'funcModule',
    FUNC_MODULE_CRUD: 'funcModule/{id}',
    //PERMISSION
    SM_PERM_GET_LIST_BY_GP: 'permission?permissionGroupId={id}',
    SM_PERM_GET_LIST: 'permission',
    SM_PERM_CREATE: 'permission',
    SM_PERM_UPDATE: 'permission',
    //USER PERMISSION
    SM_UP_GET_LIST_BY_GP: 'userPermGroup?permissionGroupId={id}&searchKey={searchKey}',
    SM_UP_CREATE: 'userPermGroup',
    SM_UP_DELETE_USER_FROM_GP: 'userPermGroup/{permGroupId}/{userId}',
    SM_UP_DELETE_ALL_UP_BY_USERID: 'userPermGroup/byUserId/{userId}',
    SM_UP_DELETE_ALL_UP_BY_GPID: 'userPermGroup/byPermGroup/{permGroupId}',
    //Business Process Step
    SM_BPS_GET_LIST: 'busiProcStep?searchKey={searchKey}',
    SM_BPS_LIST_PAGING: 'busiProcStep/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_BPS_CREATE: 'busiProcStep',
    SM_BPS_DELETE: 'busiProcStep/{id}',
    SM_BPS_UPDATE: 'busiProcStep/{id}',
    SM_BPS_DETAIL: 'busiProcStep/{id}',
    //Business Process Recipient
    SM_BPR_CREATE: 'busiProcRcpt',
    SM_BPR_GET_LIST: 'busiProcRcpt?searchKey={searchKey}&busiProcStepId={busiProcStepId}',
    // SM_BPR_GET_LIST: 'busiProcRcpt?searchKey={searchKey}&recipientId={recipientId}&busiProcStepId={busiProcStepId}',
    SM_BPR_DELETE_BY_ID: 'busiProcRcpt/{id}',
    SM_BPR_DELETE_BY_BPS_ID: 'busiProcRcpt/byBusiProcStepId/{busiProcStepId}',
    SM_BPR_DELETE_BY_RECIPIENT_ID: 'busiProcRcpt/byRecipientId/{recipientId}',
    SM_BPR_DELETE_BY_BPS_AND_RECIPIENT_ID: 'busiProcRcpt/{busiProcStepId}/{recipientId}',
    //OBJECT CLASS FOR PERMISSION
    OBJECT_TYPE_GET_CRITERIA_BY_OBJ_CLASS: "objField/byCode?classCode={objClass}&classSubCode={objSubClass}&searchKey={searchKey}&sortOrder={sort}&sortBy={sortBy}&level=1",
    OBJECT_TYPE_GET_LIST_BY_OBJ_CLASS: 'objType/byObjClass/{objClass}?searchKey={searchKey}&sortOrder={sort}&sortBy={sortBy}',
    OBJECT_TYPE_DETAIL: 'objType/{id}',
    OBJECT_TYPE_UPDATE: 'objType/{id}',
    OBJECT_TYPE_DELETE: 'objType/{id}',
    OBJECT_TYPE_CREATE: 'objType',
    //SYSTEM LOG
    SM_LOG_GET_LIST: 'SysUserLog?searchKey={searchKey}',
    SM_LOG_LIST_PAGING: 'SysUserLog/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_LOG_RD: 'SysUserLog/{id}',
    SM_LOG_EXPORT_EXCEL: 'SysUserLog/exportExcel',
    SM_LOG_GET_LIST_OFMINE: 'SysUserLog/ofMine?searchKey={searchKey}',
    SM_LOG_LIST_PAGING_OFMINE: 'SysUserLog/ofMine/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_LOG_EXPORT_EXCEL_OFMINE: 'SysUserLog/ofMine/exportExcel',
    //ACCESS LOG
    SM_ACCESS_LOG_GET_LIST: 'accessLog?searchKey={searchKey}',
    SM_ACCESS_LOG_LIST_PAGING: 'accessLog/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ACCESS_LOG_DETAIL: 'accessLog/{id}',
    SM_ACCESS_LOG_EXPORT_EXCEL: 'accessLog/exportExcel',
    SM_ACCESS_LOG_GET_LIST_OFMINE: 'accessLog/ofMine?searchKey={searchKey}',
    SM_ACCESS_LOG_LIST_PAGING_OFMINE: 'accessLog/ofMine/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}',
    SM_ACCESS_LOG_EXPORT_EXCEL_OFMINE: 'accessLog/ofMine/exportExcel',
    //DOCUMENT
    GET_STATUS_OF_DOC:'SysStatus/byObjClass/PBDocument',
    GET_STATUS_BY_OBJCLASS:'SysStatus/byObjClass/{typeCode}',

    // BACKUP
    QUICK_BACKUP: 'Configuration/backup?database={database}',
    GET_BACKUP_CONFIG: 'SyncTask?sortBy={sortBy}&sortOrder={sortOrder}&searchKey=&type=backup',
    CRUD_CONFIG: 'SyncTask',
}
