export const LayerConstant = {
    LAYER_GET_LIST_DATA: '{layer_code}/{layer_subCode}?searchKey={searchKey}',
    LAYER_GET_LIST_DATA_BY_CODE: '{layer_code}/{layer_subCode}?code={code}',
    LAYER_GET_LIST_DATA_BY_GROUP_CODE: '{layer_code}/{layer_subCode}/groupByCode?code={code}&searchKey={searchKey}',
    LAYER_GET_LIST_PAGING_DATA: '{layer_code}/{layer_subCode}/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&es=0',
    LAYER_GET_LIST_PAGING_DATA_BY_CODE: '{layer_code}/{layer_subCode}/listPaging?code={code}&pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&es=0',
    LAYER_DETAIL_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_CREATE_DATA: '{layer_code}/{layer_subCode}',
    LAYER_UPDATE_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_DELETE_DATA: '{layer_code}/{layer_subCode}/{data_id}',
    LAYER_IMPORT_DATA: '{layer_code}/{layer_subCode}/importExcel',
    LAYER_EXPORT_DATA: '{layer_code}/{layer_subCode}/exportExcel?searchKey={searchKey}',
    LAYER_EXPORT_DATA_CHEMISTRY: '{layer_code}/{layer_subCode}/exportExcel/all?searchKey={searchKey}',

    LINK_TABLE_GET_LIST_BY_CODE: '{table_code}/byCode/{code}?searchKey={searchKey}',
    LINK_TABLE_CHILDS_GET_LIST_BY_CODE: '{table_code}/byCode/{code}/{childCode}?searchKey={searchKey}',
    ENTERPRISE_DPI_GET_DETAIL_BY_CODE: 'EnterprisesDPI/byCode/{code}',
    ENTERPRISE_DPI_GET_DETAIL_BY_ID: 'EnterprisesDPI/{id}',
    ENTERPRISE_DPI_AUTO_GROUPING: 'EnterprisesDPI/Grouping',
    ENTERPRISE_DPI_LIST_PAGING: 'EnterprisesDPI/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}&minCharterCapital={minCharterCapital}&maxCharterCapital={maxCharterCapital}&typeCode={typeCode}&nationalityCode={nationalityCode}&mainSectorCode={mainSectorCode}&supportIndustryId={supportIndustryId}&group1={group1}&group2={group2}',
    ENTERPRISE_DPI_EXPORT_EXCEL: 'EnterprisesDPI/exportExcel?searchKey={searchKey}&minCharterCapital={minCharterCapital}&maxCharterCapital={maxCharterCapital}&typeCode={typeCode}&nationalityCode={nationalityCode}&mainSectorCode={mainSectorCode}&supportIndustryId={supportIndustryId}&group1={group1}&group2={group2}',

    FIELDS_GET_DROPDOWN: '{code}/{parent_id}',

    LAYER_GET_ALL_FIELDS: 'objField',
    LAYER_GET_FIELDS: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&sortOrder=asc&sortBy=seqNum',
    LAYER_GET_FIELDS_WITH_SEARCH: 'objField?objClassCode={layer_code}&objClassSubCode={layer_subCode}&searchKey={searchKey}&sortOrder=asc&sortBy=seqNum',
    LAYER_GET_FIELDS_DETAILS: 'objField/{field_id}',
    LAYER_UPDATE_FIELDS: 'objField/{field_id}',

    LAYER_LIST_GET_ALL: 'objClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder=asc&sortBy=name',
    LAYER_LIST_GET_LIST: 'objClass?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder={sort}&sortBy={sortBy}',
    LAYER_LIST_GET_LIST_BY_CODE: 'objClass?code={code}&subCode={subCode}',
    LAYER_LIST_GET_DETAIL: 'objClass/{layer_id}',
    LAYER_LIST_UPDATE: 'objClass/{layer_id}',
    LAYER_LIST_EXPORT_EXCEL: 'objClass/exportExcel',
    LAYER_LIST_BY_GROUP: 'objClass/layerGroup?searchKey={searchKey}&funcModuleCodes={funcCode}&sortOrder=asc&sortBy=Code',

    GROUP_LAYER_CRUD: 'ObjType/LayerGroup/{id}',
    GROUP_LAYER_ADD_LAYER: 'objClass/layerGroup/{id}',

    LAYER_ONCLICK_GET_DATA: "indTradeData/search",
    LAYER_SEARCH_SPATIAL: "indTradeData/getByIds",
    LAYER_SEARCH_SPATIAL_EXPORT_DATA: "indTradeData/exportExcelByIds",
    LAYER_ONCLICK_GET_DATA_ZONING: "indTradePlan/search",
    LAYER_SEARCH_SPATIAL_ZONING: "indTradePlan/getByIds",
    LAYER_SEARCH_SPATIAL_EXPORT_DATA_ZONING: "indTradePlan/exportExcelByIds",
    LAYER_SEARCH_SPATIAL_ZONING_GET_RESULT: "indTradePlan/getResult",
    LAYER_SEARCH_SPATIAL_ZONING_GET_CURRENT_DATA: "indTradePlan/getCurrentData",

    BASE_ENT_GET_LIST: "baseEnt?objClassCode={layer_code}&objClassSubCode={layer_subCode}&sortOrder=asc&sortBy=name",
    BASE_ENT_GET_SELECTBOX: "baseEnt/selectbox",
    BASE_ENT_GET_LIST_PAGING: "baseEnt/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    BASE_ENT_GET_DETAIL: "baseEnt/{id}",
    BASE_ENT_GET_DETAIL_BY_CODE: "baseEnt/byCode/{code}?objClassCode={layerCode}&objClassSubCode={layerSubcode}",
    BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE: "baseEnt/byBusinessCode/{businessCode}",
    BASE_ENT_EXPORT_EXCEL: "baseEnt/exportExcel",

    OBJ_TYPE_GET_LIST: "ObjType",
    OBJ_TYPE_GET_LIST_PAGING: "ObjType/listPaging?pageIndex={page}&sortOrder={sort}&sortBy={sortBy}&searchKey={searchKey}",
    OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS: "ObjType/byObjClass/{objClass}?searchKey={searchKey}",
    GET_LIST_SAMPLE_CERTIFICATE:"ManageCertSample",
    IMPORT_CERT_SAMPLE: "ManageCertSample/import",
    
    MENU_CRUD: "menu",
    MENU_LIST_TREE: "menu/listtree",

    MENU_PERMISSION_CRUD: "menupermission",
    MENU_PERMISSION_PERMISSIONGROUP: "menupermission/permissiongroup/{id}?sortOrder={sortOrder}&sortBy={sortBy}",
    MENU_PERMISSION_BY_TOKEN: "menupermission/ofmine/menu?sortBy={sortBy}&sortOrder={sortOrder}",
    MENU_PERMISSION_ACCEPT: "menupermission/accept?path={path}",
}
