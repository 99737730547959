import { ObjClassModel } from "./main.model";
export class SidebarParent {
    constructor() {
    }
    name: string;
    routerLink: string;
    icon: string;
    isExpand: boolean;
    isAdmin: boolean;
    child: SidebarChild[];
}

export class SidebarChild {
    constructor() {
    }
    name: string;
    routerLink: string;
    isCheckPerm?: {
        objClass: ObjClassModel
    }
}
