export class LoginModel {
    constructor() {
    }
    username: string
    phone: string
    email: string
    password: string
    deviceId: string
    otpCode: string
}

export class LoginSuccessDataModel {
    constructor() {
    }
    accessToken: string;
    user: UserDataModel;
}

export class ResetPassModel {
    constructor() {
    }
    email: string;
    newPassword: string;
    secretCode: string;
}

export class RoleModel {
    constructor() {
    }
    code: string
    name: string
    id: string
}

export class UserDataModel {
    constructor() {
    }
    birthday: string;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    gender: RoleModel;
    role: RoleModel;
    updatedAt: string;
    status: RoleModel;
    statusUpdAt: string;
    id: string;
    createdAt: string;
    department: RoleModel;
    position: RoleModel;
    avatarUrl: string;
    phone: string;
    secondPhone: string;
    address: string;
}