import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { LayerConstant } from '@core/constant/api-main/Layer_Constant';
import { Constant as MainConst } from '@core/constant/api-main/Constant';
import { Constant as SystemConst } from '@core/constant/api-system/Constant';

@Injectable({
  providedIn: 'root'
})
export class MainService {
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;

  constructor(private http: HttpClient) { }

  //#region Object Class
  getListObjClass(funcCode: string, sort: string, sortBy: string, searchKey:string, hasPerm?: number){    
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST)
                                  .replace("{searchKey}", searchKey)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{funcCode}", funcCode);
    if (typeof hasPerm == "number" && (hasPerm == 0 || hasPerm == 1)) url = url.concat("&hasPerms=" + hasPerm);

    return this.http.get(url);
  }

  getDetailObjClass(id: string){
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_DETAIL).replace("{layer_id}", id);
    return this.http.get(url);
  }

  getObjClassByCodeAndSubCode(code: string, subCode: string) {
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST_BY_CODE)
                              .replace("{code}", code);

    if (subCode) url = url.replace("{subCode}", subCode)
    else url = url.replace("&subCode={subCode}", "")
    
    return this.http.get(url);
  }
  //#endregion

  //#region Object Field
  getListObjFields(code: string, subCode: string = "", searchKey: string = "") {
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_GET_FIELDS_WITH_SEARCH).replace("{layer_code}", code)
                                                                        .replace("{layer_subCode}", subCode)
                                                                        .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getDetailObjField(id: string){
    const url = this.systemApiUrl.concat(LayerConstant.LAYER_GET_FIELDS_DETAILS).replace("{field_id}", id);
    return this.http.get(url);
  }

  //#endregion

  //#region Object Type
  getListObjTypes(searchKey: string){
    const url = this.mainApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListObjTypesByObjClass(objClassCode: string, searchKey: string){
    const url = this.mainApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST_BY_OBJ_CLASSS)
                                .replace("{objClass}", objClassCode)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListPagingObjTypes(page: number, sort: string, sortBy:string, searchKey: string){
    const url = this.mainApiUrl.concat(LayerConstant.OBJ_TYPE_GET_LIST_PAGING)
                                .replace("{page}", "" + page)
                                .replace("{sort}", sort)
                                .replace("{sortBy}", sortBy)
                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }
  //#endregion

  //#region Func Module
  getListFuncModule(){
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_GET_LIST);    
    return this.http.get(url);
  }

  updateFuncModule(id, data) {
    const url = this.systemApiUrl.concat(SystemConst.FUNC_MODULE_CRUD).replace("{id}", id);    
    return this.http.put(url, data);
  }
  //#endregion

  //#region Economic Sectors
  getListEconomicSectors(sortBy: string, sortOrder: string, searchKey: string, level: string) {
    const url = this.mainApiUrl.concat(MainConst.EC_GET_LIST_BY_LEVEL).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey)
                                                .replace("{level}", level);
    return this.http.get(url);
  }
  
  getListSupportSectors(sortBy: string, sortOrder: string, searchKey: string) {
    const url = this.mainApiUrl.concat(MainConst.SUPPORT_INDUSTRY_PARENT_LIST).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey);
    return this.http.get(url);
  }

  getListEconomicSectorChilds(sortBy: string, sortOrder: string, searchKey: string, parentId: string) {
    const url = this.mainApiUrl.concat(MainConst.EC_GET_CHILDREN).replace("{sortBy}", sortBy)
                                                .replace("{sortOrder}", sortOrder)
                                                .replace("{searchKey}", searchKey)
                                                .replace("{parentId}", parentId);
    return this.http.get(url);
  }

  getListByApiUrl(apiUrl: string){    
    let url = apiUrl.replace('{MainApiURL}/', this.mainApiUrl).replace('{SystemApiURL}/', this.systemApiUrl);
    return this.http.get(url);
  }
  //#endregion

  //#region BaseEnt
  getAllBaseEnt(){
    let url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST).replace("objClassCode={layer_code}&objClassSubCode={layer_subCode}&", "");
    return this.http.get(url);
  }

  getListBaseEnt(layerCode: string, layerSubCode: string){
    let url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST).replace("{layer_code}", layerCode);
    if (layerSubCode) url = url.replace("{layer_subCode}", layerSubCode);
    else url = url.replace("&objClassSubCode={layer_subCode}", "");

    return this.http.get(url);
  }

  getListPagingBaseEnt(page: number, sort: string, sortBy: string, searchKey: string){    
    const url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_LIST_PAGING)
                                  .replace("{page}", "" + page)
                                  .replace("{sort}", sort)
                                  .replace("{sortBy}", sortBy)
                                  .replace("{searchKey}", searchKey);

    return this.http.get(url);
  }

  getBaseEntById(id: string){
    const url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL).replace("{id}", id);
    return this.http.get(url);
  }

  getBaseEntByCode(code: string, layerCode: string, layerSubcode: string = null){
    let url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_CODE)
      .replace("{code}", code)
      .replace("{layerCode}", layerCode);

    if (layerSubcode || layerSubcode !== '') {
      url = url.replace("{layerSubcode}", layerSubcode);
    } else {
      url = url.replace("&objClassSubCode={layerSubcode}", "");
    }
    return this.http.get(url);
  }

  getBaseEntByBusinessCodev2(businessCode: string, layerCode: string, layerSubcode: string = null){
    let url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE)
      .replace("{businessCode}", businessCode)
      .replace("{layerCode}", layerCode);

    if (layerSubcode || layerSubcode !== '') {
      url = url.replace("{layerSubcode}", layerSubcode);
    } else {
      url = url.replace("&objClassSubCode={layerSubcode}", "");
    }
    return this.http.get(url);
  }

  getBaseEntByBusinessCode(businessCode: string){
    const url = this.mainApiUrl.concat(LayerConstant.BASE_ENT_GET_DETAIL_BY_BUSINESS_CODE).replace("{businessCode}", businessCode);
    return this.http.get(url);
  }

  public getChildDataByCode(tableCode: string, code: string, searchKey: string) {
    const url = this.mainApiUrl.concat(LayerConstant.LINK_TABLE_GET_LIST_BY_CODE)
                             .replace("{table_code}", tableCode)
                             .replace("{code}", code)
                             .replace("{searchKey}", searchKey);

    return this.http.get(url);
  }

  public getLayerDataByCode(code: string, layerCode: string, layerSubCode: string) {
    let url = this.mainApiUrl.concat(LayerConstant.LAYER_GET_LIST_DATA_BY_CODE)
                             .replace("{layer_code}", layerCode)
                             .replace("{code}", code);
    
    if (layerSubCode) url = url.replace("{layer_subCode}", layerSubCode);
    else url = url.replace("/{layer_subCode}", "");

    return this.http.get(url);
  }
  //#endregion

  public getMenuListTree() {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_LIST_TREE);
    return this.http.get(url);
  }

  public getMenuById(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${id}`);
    return this.http.get(url);
  }

  public getListMenuByPermissionGroup(id = '', sortOrder = 'asc', sortBy = 'seq') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_PERMISSIONGROUP)
    .replace('{id}', id)
    .replace('{sortOrder}', sortOrder)
    .replace('{sortBy}', sortBy);
    return this.http.get(url);
  }

  public getListMenuByToken(sortOrder = 'asc', sortBy = 'seq') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_BY_TOKEN)
    .replace('{sortOrder}', sortOrder)
    .replace('{sortBy}', sortBy);
    return this.http.get(url);
  }

  public createMenu(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD);
    return this.http.post(url, data);
  }

  public updateMenu(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteMenu(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_CRUD).concat(`/${id}/cascade`);
    return this.http.delete(url);
  }

  public createMenuPermission(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD);
    return this.http.post(url, data);
  }

  public updateMenuPermission(data) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD).concat(`/${data.id}`);
    return this.http.put(url, data);
  }

  public deleteMenuPermission(id) {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_CRUD).concat(`/${id}`);
    return this.http.delete(url);
  }

  public checkMenuPermission(path = '') {
    const url = this.systemApiUrl.concat(LayerConstant.MENU_PERMISSION_ACCEPT).replace('{path}', path);
    return this.http.get(url);
  }
}
