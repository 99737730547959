import { Injectable } from '@angular/core';
import { Subject } from "rxjs";
import { IMqttMessage, MqttService, MqttConnectionState } from "ngx-mqtt";
import { Observable } from "rxjs";
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@core/constant/AnyConstant';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Constant } from '@core/constant/api-notification/Constant';
import { DateLib } from '@app/core/lib/date';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  userId = CacheAll.getCookie(CacheConst.USER_ID);
  deviceId = CacheAll.getCookie(CacheConst.DEVICE_ID);
  user: string = "it2";
  pass: string = "3RP5gQbzfxeJRPxz";
  topicName: string = `${this.userId}/${this.deviceId}`;
  notificationApiUrl: string = environment.NOTIFICATION_API_URL;

  constructor(
    private _mqttService: MqttService,
    private toast: ToastrService,
    private translate: TranslateService,
    private http: HttpClient
  ) {
    // this.connectAndSubscribeToMQTT();
  }

  connectAndSubscribeToMQTT() {
    this._mqttService.connect({
      username: this.user, 
      password: this.pass,
      hostname: environment.MQTT.SERVER,
      port: environment.MQTT.PORT,
      protocol: environment.MQTT.PROTOCOL == "wss" ? "wss" : "ws"
    });

    this._mqttService.state.subscribe((s: MqttConnectionState) => {
      const status = s === MqttConnectionState.CONNECTED ? 'CONNECTED' : 'DISCONNECTED';
      if (s == 2)  {
        this.onPublishConnectSuccessfull();
        this._mqttService.state.unsubscribe();
      }
      if (s == 0) {
        this._mqttService.state.unsubscribe();
        this.toast.error(this.translate.instant("error_connection_notification_service"))
      }
    });
  }

  addTopic(): Observable<IMqttMessage> {
    return this._mqttService.observe(this.topicName);
  }

  //subscribe to connection success
  public onConnectSuccessfull = new Subject<object>();
  onConnectSuccessfullEvent = this.onConnectSuccessfull.asObservable();

  onPublishConnectSuccessfull(){
    this.onConnectSuccessfull.next();
  }

  getCurrentUserNotificationLog(){
    const url = this.notificationApiUrl.concat(Constant.NOTIFICATION_LOG_OFMINE);
    return this.http.get(url);
  }

  getListPagingCurrentUserNotificationLog(page: number, isRead?: string){
    let url = this.notificationApiUrl.concat(Constant.NOTIFICATION_LOG_OFMINE_LIST_PAGING).replace('{page}', page+"");
    if (isRead) url = url.replace('{isRead}', isRead);
    else url = url.replace('&read={isRead}', "");

    return this.http.get(url);
  }

  getListPagingAllNotificationLogWithin(numberOfDays: number, page: number){
    let toDate = DateLib.toDate("YYYYMMDD");
    let fromDate = DateLib.beforeToDate(numberOfDays, "YYYYMMDD");
    let time = DateLib.timeNow();
    
    const url = this.notificationApiUrl.concat(Constant.NOTIFICATION_LOG_OFMINE_LIST_PAGING_W_DATE)
                                        .replace('{page}', page+"")
                                        .replace('{fromDate}', fromDate + " " + time)
                                        .replace('{toDate}', toDate + " " + time);
    return this.http.get(url);
  }

  readNotificationLog(id: string){
    const url = this.notificationApiUrl.concat(Constant.NOTIFICATION_LOG_READ).replace('{id}', id);
    return this.http.get(url);
  }
}
