import { UserDataModel } from './auth.model';
import { BaseStepModel } from '@app/pages/system-management/business-process-config/shared/models/bp.model';

export class UserPermisisonModel {
    constructor() {
    }
    allowView: number;
    allowCreate: number;
    allowUpdate: number;
    allowDelete: number;
    permissionGroup: GroupPermissionModel;
    objClass: ObjClassModel;
    status: number;
    id: string;
    statusUpdAt: string;
    createdAt: string;
    updatedAt: string;
}

export class GroupPermissionModel {
    id: string;
    code : string;
    createdAt : string;
    name: string;
    status: number;
    statusUpdAt: string;
    updatedAt: string;
}

export class ObjClassModel {
    ​​​code: string;
    createdAt: string;
    funcModuleCode: string;
    hasPerm: number; ​​
    hasShape: number;  ​​
    iconUrl: string;  ​​
    id: string;​​
    layerUrl: string;  ​​
    name: string; ​​
    nameEn: string;  ​​
    shapeType: string;  ​​
    subCode: string;
}

export class UserBPPermissionModel {
    user: UserDataModel;
    baseStep: BaseStepModel;
    id: string;
}

export class CreateUserBPPermissionModel {
    user: {
        id: string;
    };
    baseStep: {
        id: string
    };
}