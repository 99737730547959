export class DateLib {

    public static toDate(format: string) {
        let t = new Date();
        let date = "";
        switch (format) {
            case "DDMMYYYY":
                date = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear(); 
                break;
            case "MMDDYYYY":
                date = (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate() +"-" + t.getFullYear();
                break;
            case "YYYYMMDD":
                date = t.getFullYear() + "-" + (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate();
                break;
            default:
                date = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear();
                break;
        }
        return date;
    }

    public static beforeToDate(numOfDays: number, format: string) {
        let date = new Date;
        var t = new Date(date.getTime() - (numOfDays * 24 * 60 * 60 * 1000));
        let dateStr = "";
        switch (format) {
            case "DDMMYYYY":
                dateStr = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear(); 
                break;
            case "MMDDYYYY":
                dateStr = (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate() +"-" + t.getFullYear();
                break;
            case "YYYYMMDD":
                dateStr = t.getFullYear() + "-" + (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate();
                break;
            default:
                dateStr = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear();
                break;
        }
        return dateStr;
    }

    public static afterToDate(numOfDays: number, format: string) {
        let date = new Date;
        var t = new Date(date.getTime() + (numOfDays * 24 * 60 * 60 * 1000));
        let dateStr = "";
        switch (format) {
            case "DDMMYYYY":
                dateStr = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear(); 
                break;
            case "MMDDYYYY":
                dateStr = (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate() +"-" + t.getFullYear();
                break;
            case "YYYYMMDD":
                dateStr = t.getFullYear() + "-" + (((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ ((t.getDate() < 10)?"0":"") + t.getDate();
                break;
            default:
                dateStr = ((t.getDate() < 10)?"0":"") + t.getDate() +"-"+(((t.getMonth()+1) < 10)?"0":"") + (t.getMonth()+1) +"-"+ t.getFullYear();
                break;
        }
        return dateStr;
    }

    public static timeNow() {
        let t = new Date();
        return ((t.getHours() < 10)?"0":"") + t.getHours() +":"+ ((t.getMinutes() < 10)?"0":"") + t.getMinutes() +":"+ ((t.getSeconds() < 10)?"0":"") + t.getSeconds();    
    }
}

