import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@core/guard/auth.guard';
import { AdminGuard } from '@core/guard/admin.guard';
import { EntGuard } from '@core/guard/enterprise.guard';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';

const routes: Routes = [
  { path: '', redirectTo: SystemRoutingEnum.AUTH, pathMatch: 'full' },
  { path: SystemRoutingEnum.AUTH, loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule) },  
  { path: SystemRoutingEnum.USER_PROFILE, canLoad:[EntGuard], loadChildren: () => import('./pages/user/user.module').then(m => m.UserModule) },
  { path: SystemRoutingEnum.ERROR, loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule) },
  { path: SystemRoutingEnum.DATA_MANAGEMENT, redirectTo: `${SystemRoutingEnum.DATA_MANAGEMENT}/maps`, pathMatch: 'full' },
  { path: `${SystemRoutingEnum.DATA_MANAGEMENT}/maps`, canLoad:[AuthGuard], loadChildren: () => import('./pages/maps/data-management/data-management.module').then(m => m.DataManagementModule) },
  { path: `${SystemRoutingEnum.DATA_MANAGEMENT}/extended`, canLoad:[AuthGuard], loadChildren: () => import('./pages/maps/extended/extended.module').then(m => m.ExtendedModule) },
  { path: SystemRoutingEnum.ZONING_MANAGEMENT, canLoad:[AuthGuard], loadChildren: () => import('./pages/zoning-management/zoning-management.module').then(m => m.ZoningManagementModule) },
  { path: SystemRoutingEnum.SYSTEM_MANAGEMENT, canLoad:[AdminGuard], loadChildren: () => import('./pages/system-management/system-management.module').then(m => m.SystemManagementModule) },
  { path: SystemRoutingEnum.CATEGORY, canLoad:[AuthGuard], loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule) },
  { path: SystemRoutingEnum.STATISTIC, canLoad:[AuthGuard], loadChildren: () => import('./pages/statistic/statistic.module').then(m => m.StatisticModule) },
  { path: SystemRoutingEnum.DATA_SHARING, canLoad:[AdminGuard], loadChildren: () => import('./pages/data-sharing/data-sharing.module').then(m => m.DataSharingModule) },
  { path: SystemRoutingEnum.BUSINESS_PROCESS, canLoad:[AuthGuard], loadChildren: () => import('./pages/major-handling/major-handling.module').then(m => m.MajorHandlingModule) },
  { path: SystemRoutingEnum.INDICATOR, canLoad:[AuthGuard], loadChildren: () => import('./pages/indicators/indicators.module').then(m => m.IndicatorsModule) },
  { path: SystemRoutingEnum.DIGITIZED_RECORD, canLoad:[AuthGuard], loadChildren: () => import('./pages/digitized-records/digitized-records.module').then(m => m.DigitizedRecordsModule) },
  { path: SystemRoutingEnum.BUSINESS_REPORT, canLoad:[EntGuard], loadChildren: () => import('./pages/subtable/subtable.module').then(m => m.SubtableModule) },
  { path: SystemRoutingEnum.INSPECTION, canLoad:[AuthGuard], loadChildren: () => import('./pages/inspection/inspection.module').then(m => m.InspectionModule) },
  { path: SystemRoutingEnum.FIELD_SURVEY, canLoad:[AuthGuard], loadChildren: () => import('./pages/field-survey/field-survey.module').then(m => m.FieldSurveyModule) },
  { path: SystemRoutingEnum.REPORT_FORM, loadChildren: () => import('./pages/report-form/report-form.module').then(m => m.ReportFormModule) },
  { path: '**', redirectTo: SystemRoutingEnum.ERROR},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
