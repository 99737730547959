import { Injectable } from '@angular/core';
import { CanActivate, CanLoad } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheAll } from '../lib/cache';
import { CacheConst } from '../constant/AnyConstant';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanLoad {
  currentUrl: string = ""

  constructor(private authService: AuthService, public routerService: Router, public toast: ToastrService, public translate:TranslateService) {}  

  canActivate() :boolean {
    this.currentUrl = this.routerService.url;
    let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
    if (this.authService.isLoginUser()) {
      if (this.authService.isAdmin()) return true;
      else {
        this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
      }
    }
    else {
      if (userSession) {
        CacheAll.deleteCookie(CacheConst.USER_SESSION);
        this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
      } else {
        this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
      }
      this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
      return false;
    }
  }

  canLoad() :boolean {
    this.currentUrl = this.routerService.url;
    let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
    if (this.authService.isLoginUser()) {
      if (this.authService.isAdmin()) return true;
      else {
        this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
      }
    }
    else {
      if (userSession) {
        CacheAll.deleteCookie(CacheConst.USER_SESSION);
        this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
      } else {
        this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
      }
      this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
      return false;
    }
  }
}
