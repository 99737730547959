const hostname: string = "https://ct.binhduong.gov.vn";
// const arcgisServerHost: string = "https://ct.binhduong.gov.vn:6443";
const arcgisServerHost: string = "https://ct.binhduong.gov.vn";
export const environment = {
  production: true,
  SYSTEM_API_URL: hostname + '/doit-api-system/v1/',
  MAIN_API_URL: hostname + '/doit-api-main/v1/',
  SURVEY_API_URL: hostname + '/doit-api-fieldsurveytool/v1/',
  NOTIFICATION_API_URL: hostname + '/doit-api-notification/v1/',
  API_LGSP: 'https://api.binhduong.gov.vn:8687/',
  REPORT_API_URL: hostname + '/doit-api-report/v1/',
  ARCGIS_SERVER_URL: arcgisServerHost + '/arcgis/rest/services',
  ARCGIS_SXD_URL: 'http://gisxd.binhduong.gov.vn:81',
  MQTT: {
		SERVER: '113.161.160.168',
		PROTOCOL: 'ws',
		PORT: 8083
	},
  VERSION: 'v1.0.25',
  PROJCS: {
    NAME: "BINHDUONG_VN2000",
    CENTRAL_MERIDIAN: 105.75
  },
  DOWNLOAD_APP: {
    ANDROID_URL: hostname + '/assets/file/mobiles/DOIT_BDG_PROD.apk',
    IOS_URL: hostname + '/assets/file/mobiles/DOIT_BDG_PROD.plist'
  },
  BACKUP_NAME: 'DOIT_BD',
  BASEMAP_SETTINGS_PW: '8YPQJV6gSn!2',
};
