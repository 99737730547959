import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Constant } from '@core/constant/api-system/Constant';
import { UserPermisisonModel, ObjClassModel} from '@shared/models/user-permission.model';
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@app/core/constant/AnyConstant';
import { ApiReturnModel } from '@shared/models/global.model';
import { AuthService } from '@core/services/auth/auth.service';
import { PermissionType } from '../enums/permission.enum';
import { LayerConstant } from '@core/constant/api-main/Layer_Constant';
import { UserBPPermissionModel, CreateUserBPPermissionModel } from '@shared/models/user-permission.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserPermissionService {
  /**Kiểm tra nếu không phải ROLE ADMIN thì mới sử dụng Permission Service*/ 
  systemApiUrl = environment.SYSTEM_API_URL;
  mainApiUrl = environment.MAIN_API_URL;
  userId: string = "";
  _isAdmin: boolean = false;
  _currentRole: string = "";
  _currentPermission: UserPermisisonModel[] = [];
  _isGetPerm: boolean = false;

  constructor(private http: HttpClient, private authService: AuthService, private routerService: Router) { 
    this.userId = CacheAll.getCookie(CacheConst.USER_ID);
    this._isAdmin = this.authService.isAdmin();
    this._currentRole = this.authService.getCurrentRole();
  }

  isAdmin(){
    return this._isAdmin;
  }

  getCurrentRole(){
    return this._currentRole;
  }

  getObjClassByCode(code: string, subCode: string){
    let url = this.systemApiUrl.concat(LayerConstant.LAYER_LIST_GET_LIST_BY_CODE)
                                  .replace("{code}", code);
    if (subCode) url = url.replace("{subCode}", subCode);
    else url = url.replace("&subCode={subCode}", "");

    return this.http.get(url);
  }

  getCurrentPermission(callback) {
    if (this._currentPermission.length > 0) callback(this._currentPermission);
    else {
      this.getUserPermissionByToken().subscribe(rs => {
        let result = rs as ApiReturnModel;
        if (result.code == 0) {
          this._currentPermission = result.data;
          callback(this._currentPermission);
        } else {
          callback([]);
        }
      }, error => {
        callback([]);
      })
    }
  }

  getUserPermissionByToken(){
    const url = this.systemApiUrl.concat(Constant.AUTH_USER_PERMISSION);
    return this.http.get(url);
  }

  checkPermission(objClass: ObjClassModel, type: PermissionType){
    if (this._isAdmin) return true;
    else if (this._currentRole && this._currentRole != "ADMIN") {
      if (this._currentPermission.length == 0 && !this._isGetPerm) {
        this.getUserPermissionByToken().subscribe(rs => {
          let result = rs as ApiReturnModel;
          if (result.code == 0) {
            this._isGetPerm = true;
            this._currentPermission = result.data;
            let allValidPerm = [] as UserPermisisonModel[];
            let permToCheck;
            let isHavePerm;
            for (let perm of this._currentPermission) {
              if (perm.objClass.id === objClass.id) allValidPerm.push(perm);
            }
            if (allValidPerm.length > 0) {
              permToCheck = this.mergePermission(allValidPerm);
              if (permToCheck) {
                isHavePerm = this.checkByType(permToCheck, type)
              } else {
                isHavePerm = false;
              }
            } else {
              isHavePerm = false;
            }
            //--- Specialized for "LGSP" Role --- 
            if (this._currentRole == "LGSP" && type != PermissionType.view) isHavePerm = false;
            return isHavePerm;
          }
        })
      } else {
        let allValidPerm = [] as UserPermisisonModel[];
        let permToCheck;
        let isHavePerm;
        for (let perm of this._currentPermission) {
          if (perm.objClass.id === objClass.id) allValidPerm.push(perm);
        }
        if (allValidPerm.length > 0) {
          permToCheck = this.mergePermission(allValidPerm);
          if (permToCheck) {
            isHavePerm = this.checkByType(permToCheck, type)
          } else {
            isHavePerm = false;
          }
        } else {
          isHavePerm = false;
        }
        //--- Specialized for "LGSP" Role --- 
        if (this._currentRole == "LGSP" && type != PermissionType.view) isHavePerm = false;
        return isHavePerm;
      }
    }
  }

  checkByType(perm: UserPermisisonModel, type: PermissionType){
    switch (type) {
      case "view":
        if (perm.allowView === 1) return true;
        else return false;
      case "update":
        if (perm.allowUpdate === 1 && perm.allowView === 1) return true;
        else return false;
      case "create":
        if (perm.allowCreate === 1 && perm.allowView === 1) return true;
        else return false;
      case "delete":
        if (perm.allowDelete === 1 && perm.allowView === 1) return true;
        else return false;
      default:
        return false;
    }
  }

  mergePermission(perms: UserPermisisonModel[]){
    let returnData = new UserPermisisonModel;
    for (let perm of perms){
      if (returnData) {
        returnData.allowView = returnData.allowView || perm.allowView;
        returnData.allowCreate = returnData.allowCreate || perm.allowCreate;
        returnData.allowUpdate = returnData.allowUpdate || perm.allowUpdate;
        returnData.allowDelete = returnData.allowDelete || perm.allowDelete;
      } else {
        returnData = perm;
      }
    }
    return returnData;
  }

  //#region Business Process Permission
  getCurrentUserPBPermissionByUserId(){
    const url = this.mainApiUrl.concat(Constant.GET_BP_PERMISSON).replace("{userId}", this.userId);
    return this.http.get(url);
  }

  getUserPBPermissionByUserId(userId: string){
    const url = this.mainApiUrl.concat(Constant.GET_BP_PERMISSON).replace("{userId}", userId);
    return this.http.get(url);
  }

  getUserPBPermissionByBaseStepId(baseStepId: string){ 
    const url = this.mainApiUrl.concat(Constant.GET_BP_PERMISSON_BY_BASE_STEP).replace("{baseStepId}", baseStepId);
    return this.http.get(url);
  }

  getListPagingUserPBPermission(userId: string, baseStepId: string, departmentId: string, page: number, sort: string, sortBy:string, searchKey: string) {
    let url = this.mainApiUrl.concat(Constant.GET_LIST_PAGING_BP_PERMISSON)
                                        .replace("{page}", "" + page)
                                        .replace("{sort}", sort)
                                        .replace("{sortBy}", sortBy)
                                        .replace("{searchKey}", searchKey);    
    if (userId) url = url.replace("{userId}", userId);
    else url = url.replace("&userId={userId}", "");
    if (baseStepId) url = url.replace("{baseStepId}", baseStepId);
    else url = url.replace("&baseStepId={baseStepId}", "");
    if (departmentId) url = url.replace("{departmentId}", departmentId);
    else url = url.replace("&departmentId={departmentId}", "");

    return this.http.get(url);
  }

  createUserPBPermission(data: CreateUserBPPermissionModel){
    const url = this.mainApiUrl.concat(Constant.CRUD_BP_PERMISSON).replace("/{id}", "");
    return this.http.post(url, data);
  }

  updateUserPBPermission(id: string, data: CreateUserBPPermissionModel){
    const url = this.mainApiUrl.concat(Constant.CRUD_BP_PERMISSON).replace("{id}", id);
    return this.http.put(url, data);
  }

  deleteUserPBPermission(id: string){
    const url = this.mainApiUrl.concat(Constant.CRUD_BP_PERMISSON).replace("{id}", id);
    return this.http.delete(url);
  }

  checkPermissionToAccessBPPage(baseStepCode: string){
    this.getCurrentUserPBPermissionByUserId().subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        let currentUserPBPermission = result.data as UserBPPermissionModel[];
        let isHavePerm = false;
        for (let perm of currentUserPBPermission) if (perm.baseStep.code === baseStepCode) isHavePerm = true;
        if (!isHavePerm) this.routerService.navigate(['error/401'], {queryParams: {}});
      } else {
        this.routerService.navigate(['error/401'], {queryParams: {}});
      }
    }, error => {
      this.routerService.navigate(['error/401'], {queryParams: {}});
    })
  }
  //#endregion
}
