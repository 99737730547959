import { Component, OnInit, Input, ViewChild } from '@angular/core';
import {NgbModal, ModalDismissReasons, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'notify-modal',
  templateUrl: './notify-modal.component.html',
  styleUrls: ['./notify-modal.component.scss']
})
export class NotifyModalComponent implements OnInit {

  @Input() type:string;
  @Input() title:string;
  @Input() content:string;
  @Input() centerPage:boolean;
  @Input() size: string;

  @ViewChild('confirmModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    if (!this.type) this.type = 'default'; // type: default, info, success, warning, danger
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
  }


  open() {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }

}
