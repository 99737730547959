<div class="container-scroller">
    <!-- version 04/06/2022 -->
    <!-- <app-navbar *ngIf="showNavbar"></app-navbar> -->
    <div class="container-fluid page-body-wrapper">
        <button  *ngIf="showSidebar && isLoginUser" class="btn btn-secondary position-absolute toggle-button" [ngClass]="{'left-260px': !iconOnlyToggled, 'left-70px': iconOnlyToggled}" (click)="toggleSidebar()">
            <em *ngIf="iconOnlyToggled" class="mdi mdi-menu-right-outline" title="{{'global_expand' | translate}}"></em>
            <em *ngIf="!iconOnlyToggled" class="mdi mdi-menu-left-outline"></em>
        </button>
        <button *ngIf="showSidebar && isLoginUser" class="btn btn-secondary position-fixed menu-toggle-button" (click)="toggleOffcanvas()">
            <em class="mdi mdi-menu"></em>
        </button>
        <app-sidebar *ngIf="isLoginUser" [menu]="menu"></app-sidebar>
        <div class="main-panel" [ngClass]="{ 'main-panel-only' : !showSidebar }">
            <div [ngClass] ="'main-content'">
                <app-spinner *ngIf="isLoading"></app-spinner>
                <div contentAnimate *ngIf="!isLoading" class="h-100">
                    <router-outlet></router-outlet>
                </div>
            </div>
            <!-- <app-footer *ngIf="showFooter"></app-footer> -->
        </div>
    </div>
</div>