import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  RouterStateSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { MainService } from '../services/main/main.service';
import { ApiReturnModel } from '@app/shared/models/global.model';
import { MenuStateService } from '../services/state/menu-state.service';
import { SidebarParent } from '@app/shared/models/sidebar.model';

@Injectable({
  providedIn: 'root',
})
export class MenuPermissionGuard implements CanActivate, CanLoad {
  menu: SidebarParent[];
  currentUrl: string = '';

  constructor(
    public mainService: MainService,
    public routerService: Router,
    public menuStateService: MenuStateService
  ) {
    this.menuStateService.execChange.subscribe((value) => {
      this.menu = value;
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // this.currentUrl = this.routerService.url;
    // if (
    //   this.currentUrl.includes(`/${SystemRoutingEnum.AUTH}`) ||
    //   this.currentUrl.includes(`/${SystemRoutingEnum.ERROR}`)
    // )
    //   return true;
    if (!this.menu) return true;
    this.currentUrl = state.url;
    // this.currentUrl = this.currentUrl === '/' ? state.url : this.currentUrl;
    // const menuString = localStorage.getItem('menu');
    // const menu = JSON.parse(menuString);
    // const result = this.findItem(this.currentUrl, menu);
    const urlWithoutParam = this.currentUrl.split('?');
    const menuArray = urlWithoutParam[0]
      .split('/')
      .filter((element) => element);
    const parentPath = menuArray.slice(0, 1).join();

    const parentRouterLink = this.menu?.find(
      (item) => item.routerLink.replace('/', '') === parentPath
    );
    if (!parentRouterLink) {
      this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
    }

    if (menuArray.length === 1) {
      this.checkMenuPermission(parentRouterLink.routerLink);
      return true;
    }

    const childPath = '/' + menuArray.slice(0, 2).join('/');
    const result = parentRouterLink?.child?.find(
      (item) =>
        item.routerLink.includes(childPath) ||
        childPath.includes(item.routerLink)
    ) ?? parentRouterLink;

    if (!result) {
      this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
    }

    this.checkMenuPermission(result.routerLink);
    return true;
  }

  canLoad(): boolean {
    return true;
  }

  checkMenuPermission(path) {
    this.mainService.checkMenuPermission(path).subscribe((res) => {
      const result = res as ApiReturnModel;
      if (result.code != 0) {
        this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      }
    });
  }

  // findItem(searchItem, array) {
  //   let result;
  //   if (!array) return result;
  //   for (const item of array) {
  //     if (item.child?.length === 0) {
  //       if (searchItem.includes(item.routerLink)) {
  //         result = item.routerLink ?? result;
  //         break;
  //       }
  //     }
  //     result = this.findItem(searchItem, item.child) ?? result;
  //   }
  //   return result;
  // }
}
