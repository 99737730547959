import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheAll } from '../lib/cache';
import { CacheConst } from '../constant/AnyConstant';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { MainService } from '../services/main/main.service';
import { MenuPermissionGuard } from './menu-permission.guard';
import { MenuStateService } from '../services/state/menu-state.service';

@Injectable({
  providedIn: 'root'
})
export class EntGuard extends MenuPermissionGuard implements CanActivate, CanLoad {
  currentUrl: string = ""

  constructor(private authService: AuthService, public mainService: MainService, public routerService: Router, public toast: ToastrService, public translate:TranslateService, public menuStateService: MenuStateService) { super(mainService, routerService, menuStateService); }  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :boolean {
    this.currentUrl = this.routerService.url;
    let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
    if (this.authService.isLoginUser()) {
      return super.canActivate(route, state);
      // return true;
    }
    else {
      if (userSession) {
        CacheAll.deleteCookie(CacheConst.USER_SESSION);
        this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
      } else {
        this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
      }
      this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
      return false;
    }
  }

  canLoad() :boolean {
    this.currentUrl = this.routerService.url;
    let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
    if (this.authService.isLoginUser()) return true;
    else {
      if (userSession) {
        CacheAll.deleteCookie(CacheConst.USER_SESSION);
        this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
      } else {
        this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
      }
      this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
      return false;
    }
  }
}
