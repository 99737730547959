import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from "@core/services/notification/notification.service";
import { ApiReturnModel, ListPagingModel } from '@shared/models/global.model';
import { NotificationLogModel } from '../models/notification.model';
import { AuthService } from '@core/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

@Component({
  selector: 'notification-center',
  templateUrl: './notification-center.component.html',
  styleUrls: ['./notification-center.component.scss']
})
export class NotificationCenterComponent implements OnInit {
  @Output() onRead = new EventEmitter<object>();
  @Input() centerPage:boolean;
  @Input() size: string;
  scrollbarConfig: PerfectScrollbarConfigInterface = {};

  showingNotification: NotificationLogModel[] = [];
  totalNotifications: number = null;
  @ViewChild('notificationCenterModal') modal: any;
  
  closeResult: string;
  modalReference: NgbModalRef;

  page: number = 1;

  constructor(private modalService: NgbModal, private readonly notificationService: NotificationService, public authService: AuthService, private toast:ToastrService, public translate: TranslateService) { }

  ngOnInit(): void {
    if (!this.centerPage) this.centerPage = false;
    if (!this.size) this.size = 'md'; //size: sm, md, lg
  }


  open() {
    this.modalReference = this.modalService.open(this.modal, {
      ariaLabelledBy: 'modal-basic-title', 
      centered: this.centerPage,
      size: this.size
    });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
    this.page = 1;
    this.loadData(this.page);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  close(){
    this.modalReference.close();
  }

  loadData(page: number){
    // this.notificationService.getListPagingAllNotificationLogWithin(30, page).subscribe(rs => {
    this.notificationService.getListPagingCurrentUserNotificationLog(page).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        let data = result.data as ListPagingModel;
        this.totalNotifications = data.totalElements;
        this.showingNotification = data.content;
        if (this.totalNotifications > 0 && this.showingNotification.length == 0) {
          this.page = data.totalPages;
          this.loadData(this.page);
        }
      } else {
        if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(error.message);
    })
  }

  changePage($event) {
    this.page = $event as number;
    this.loadData(this.page);
  }

  submitRead(notification: NotificationLogModel){
    this.notificationService.readNotificationLog(notification.id).subscribe(rs => {
      let result = rs as ApiReturnModel;
      if (result.code == 0) {
        this.loadData(this.page);
        this.onRead.emit();
      } else {
        if (!this.authService.checkInvalidResponse(result)) this.toast.error(result.message);
      }
    }, error => {
      this.toast.error(error.message);
    })
  }

}
