import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CacheAll } from '../lib/cache';
import { CacheConst } from '../constant/AnyConstant';
import { SystemRoutingEnum } from '@shared/enums/routing.enum';
import { MenuPermissionGuard } from './menu-permission.guard';
import { MainService } from '../services/main/main.service';
import { MenuStateService } from '../services/state/menu-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard extends MenuPermissionGuard implements CanActivate, CanLoad {
  currentUrl: string = ""

  constructor(private authService: AuthService, public mainService: MainService, public routerService: Router, public toast: ToastrService, public translate:TranslateService, public menuStateService: MenuStateService) { super(mainService, routerService, menuStateService); }  

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) :boolean {
    //get current user and check valid token
    //check token if need
    let role = this.authService.getCurrentRole();
    if (role != "ENTERPRISE") {
      this.currentUrl = this.routerService.url;
      let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
      if (this.authService.isLoginUser()) {
        return super.canActivate(route, state);
        // return true;
      }
      else {
        if (userSession) {
          CacheAll.deleteCookie(CacheConst.USER_SESSION);
          this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
        } else {
          this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
        }
        this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
        return false;
      }
    } else {
      this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
    }
    
  }

  canLoad() :boolean {
    let role = this.authService.getCurrentRole();
    if (role != "ENTERPRISE") { 
      this.currentUrl = this.routerService.url;
      let userSession = CacheAll.getCookie(CacheConst.USER_SESSION); 
      if (this.authService.isLoginUser()) return true;
      else {
        if (userSession) {
          CacheAll.deleteCookie(CacheConst.USER_SESSION);
          this.toast.warning("Đã hết phiên đăng nhập. Vui lòng đăng nhập để sử dụng phần mềm!");
        } else {
          this.toast.warning("Vui lòng đăng nhập để sử dụng phần mềm!")
        }
        this.routerService.navigate([SystemRoutingEnum.AUTH], {queryParams: {redirectUrl: this.currentUrl}});
        return false;
      }
    } else {
      this.routerService.navigate([`${SystemRoutingEnum.ERROR}/401`]);
      return false;
    } 
  }
}
