import { ShapeModel } from "./global.model";

export class ObjClassModel {
    code: string;
    createdAt: string;
    funcModuleCode: string;
    hasPerm: number;
    hasShape: number;
    iconUrl: string;
    id: string;
    layerUrl: string;
    name: string;
    nameEn: string;
    shapeType: string;
    subCode: string;
    extra: string;
}

export class ObjectFieldModel {
    allowShare: number;
    allowStatistic?: number;
    value?: any;
    code: string;
    createdAt: string;
    dataType: string;
    id: string;
    name: string;
    nameEn: string;
    objClassCode: string;
    objClassSubCode: string;
    apiURL: string;
    componentUI: string;
    seqNum: number;
    required: number;
    parentCode: string;
    parentCondition: string;
    preventEdit?: number;
    minSize?: number;
    maxSize?: number;
    minValue?: string;
    maxValue?: string;
    validateRegex?: string;
    extra: string;
}

export class ObjTypeModel {
    id?:	string;
    createdAt?: string;
    updatedAt?: string;
    code: string;
    name: string;
    objClass: string;
    styleUI?: string;
}

export class EnterpriseDpiModel {
    name: string;
    abbrName: string;
    code: string;
    address: string;
    email: string;
    phoneNumber: string;
    establishYear: string;
    note: string;
    economicSectors: EconomicSectorModel[];
    id: string;
    objectId: number;
    status: string;
    statusName: string;
    shape: ShapeModel;
    ward: any;
    district: any;
    legalRep: string;
    charterCapital:	number;
    mainSector: EconomicSectorModel;
    enterpriseStatus: number;
    registrationChange:	string;
    numberOfLabor: number;
    group: EconomicSectorModel;
    type: ObjTypeModel;
    enterpriseType:	ObjTypeModel;
    createdAt: string;
    updatedAt: string;
    statusUpdAt: string;
}

export class EconomicSectorModel { 
    id:	string;
    createdAt: string;
    updatedAt: string;
    code: string;
    name: string;
    show: string;
    parentCode:	string;
    parentId: string;
    level: string;
    lgspId:	string;
    syncStatus:	string;
    syncDate: string;
    parent:	EconomicSectorModel;
    childCount:	number;
}

export class BaseEntModel {
    "code": string;
    "name": string;
    "economicSectors": EconomicSectorModel[];
    "district": {
      "code": string;
      "name": string;
      "id": string;
    };
    "objClass": {
      "code": string;
      "subCode": string;
      "name": string;
      "nameEn": string;
    };
    "shapeCoords": string;
    "id": string;
    "address": string;
    "status": string;
    "statusUpdAt": string;
    "createdAt": string;
    "updatedAt": string;
    "businessCode": string;
    "businessName": string;
    "businessAddress": string;
}

export class DistrictModel {
    code: string;
    name: string;
    nameEn: string;
    level: string;
    id: string;
    createdAt: string;
    lgspId: string;
    syncStatus: string;
    syncDate: string;
}

export class WardModel {
    code: string;
    name: string;
    nameEn: string;
    level: string;
    id: string;
    createdAt: string;
    lgspId: string;
    syncStatus: string;
    syncDate: string;
    district: DistrictModel;
}