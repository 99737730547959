import { BrowserModule } from '@angular/platform-browser';
import { Injectable, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { ClipboardModule } from 'ngx-clipboard';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HighchartsChartModule } from 'highcharts-angular';
import { ToastrModule } from 'ngx-toastr';
import { MqttModule } from 'ngx-mqtt';

import { AppComponent } from './app.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentAnimateDirective } from './shared/directives/content-animate.directive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReusedModule } from '@reused/reused.module';
import { SharedModule } from '@shared/shared.module';

import { AuthService } from '@core/services/auth/auth.service';
import { ErrorInterceptorProvider } from './core/interceptor/handle-error.intercept';
import { HeaderInterceptorProvider } from './core/interceptor/header-config.intercept';
import { MenuStateService } from './core/services/state/menu-state.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as en from '@assets/i18n/en.json';
import * as vi from '@assets/i18n/vi.json';

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<any> {
    return of(lang === 'vi' ? vi : en).pipe(map((item) => item['default']));
  }
}

@NgModule({
  declarations: [AppComponent, SidebarComponent, ContentAnimateDirective],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    HighchartsChartModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    MqttModule.forRoot({ connectOnCreate: false }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient],
      },
      defaultLanguage: 'vi',
      useDefaultLang: true,
    }),
    ReusedModule,
    SharedModule,
  ],
  providers: [
    AuthService,
    MenuStateService,
    HeaderInterceptorProvider,
    ErrorInterceptorProvider
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
