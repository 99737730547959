import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CacheAll } from '@core/lib/cache';
import { CacheConst } from '@core/constant/AnyConstant';
import { environment } from '@env/environment';

@Injectable()
export class HeaderConfigInterceptor implements HttpInterceptor {
  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = CacheAll.getCookie(CacheConst.USER_TOKEN);
    let clonedRequest;
    /**
     * But when the data of the body is an instance of FormData, we can assume that we are uploading an file.
     * Therefore, we need to change the contentType to multipart/form-data.
     */
    let LGSPURL = environment.API_LGSP;
    if (!httpRequest.url.includes(LGSPURL)) {
      if (httpRequest.body instanceof FormData) {
        clonedRequest = httpRequest.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + token,
          }
        });
      } else {
        clonedRequest = httpRequest.clone({
          setHeaders: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
          }
        });
      }
    } else {
      clonedRequest = httpRequest.clone({
        setHeaders: {
          'Content-Type': 'application/json'
        }
      });
    }

    return next.handle(clonedRequest);
  }
}

/**
 * Provider POJO for the interceptor
 */
 export const HeaderInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HeaderConfigInterceptor,
    multi: true
};