export enum SystemRoutingEnum {
  HOME = "data-management",
  ENT_ROLE_HOME = "business-report",
  AUTH = "auth",
  USER_PROFILE = "user",
  ERROR = "error",
  SYSTEM_MANAGEMENT = "system-management",
  DATA_MANAGEMENT = "data-management",
  ZONING_MANAGEMENT = "zoning-management",
  CATEGORY = "category",
  REPORT_FORM = "report-form",
  STATISTIC = "statistic",
  DATA_SHARING = "data-sharing",
  BUSINESS_PROCESS = "major-handling",
  INDICATOR = "indicators",
  BUSINESS_REPORT = "business-report",
  DIGITIZED_RECORD = "digitized-records",
  INSPECTION = "inspection",
  FIELD_SURVEY = "field-survey"
}
