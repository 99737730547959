import { Component, Inject, Input, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { PubSubService } from "@core/services/pub-sub/pub-sub.service";
import { SidebarParent } from "../models/sidebar.model";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { AuthService } from "@core/services/auth/auth.service";
import { ApiReturnModel, ListPagingModel } from "@shared/models/global.model";
import { ToastrService } from "ngx-toastr";
import { CacheAll } from "@core/lib/cache";
import { TTL, CacheConst } from "@app/core/constant/AnyConstant";
import { Base64 } from "@core/lib/encode";
import { environment } from "@env/environment";
import { UserDataModel } from "../models/auth.model";
import { NotificationService } from "@core/services/notification/notification.service";
import { Subscription } from "rxjs";
import { NotifyModalComponent } from "@reused/notify-modal/notify-modal.component";
import { UserPermissionService } from "@core/services/permission/services/user-permission.service";
import { UserBPPermissionModel } from "@shared/models/user-permission.model";
import { NotificationCenterComponent } from "../notification-center/notification-center.component";
import { MainService } from "@core/services/main/main.service";
import { ObjClassModel } from "../models/main.model";
import { PermissionType } from "@core/services/permission/enums/permission.enum";
import { SystemRoutingEnum } from "@shared/enums/routing.enum";
import { FieldSurveyRoutingEnum } from "@app/pages/field-survey/shared/enums/routing.enum";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() set menu(value: SidebarParent[]) {
    this._showMenuData = value;
  }
  get showMenuData(): SidebarParent[] {
    return this.searchMenuLocal() ?? [];
  }

  public uiBasicCollapsed = false;
  public samplePagesCollapsed = false;
  appVersion: string = environment.VERSION;
  activeUrl: string = "";
  sidebarData: SidebarParent[] = [];
  sidebarEntData: SidebarParent = new SidebarParent();
  _showMenuData: SidebarParent[] = [];
  scrollbarConfigSidebar: PerfectScrollbarConfigInterface = { suppressScrollX: false };
  avatarUrl: string;
  isAdmin: boolean = false;
  isEnt: boolean = false;
  userLastname: string = "H";
  userFirstname: string = "T";
  isCollapsedSidebar: boolean = false;
  currentUser: UserDataModel = new UserDataModel();

  subscription: Subscription;
  subsFromConnectionSuccess: Subscription;
  subsFromAvatarChange: Subscription;
  subsFromCollapseMenu: Subscription;

  searchKey: string = "";

  type: string = "warning";
  content: string = "";
  title: string = "";
  @ViewChild(NotifyModalComponent) _notiModal: NotifyModalComponent;

  @ViewChild(NotificationCenterComponent)
  _notificationCenter: NotificationCenterComponent;
  totalNotifications: number = null;
  notificationInterval: any;

  userBPPermissionData: UserBPPermissionModel[] = [];

  listObjClasses: ObjClassModel[] = [];
  _permissionViewType: PermissionType = PermissionType.view;

  hoverName: string = "";
  homeUrl: string = "";

  constructor(
    private routerService: Router,
    public translate: TranslateService,
    private pubSubService: PubSubService,
    private authService: AuthService,
    public toast: ToastrService,
    public userPermService: UserPermissionService,
    private readonly notificationService: NotificationService,
    private mainService: MainService
  ) {
    this.routerService.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.activeUrl = this.getMainRouterURL();
        this.showMenuData.forEach(menu => {
          menu.isExpand = this.activeUrl.includes(menu.routerLink);
        });
      }
    });

    this.subsFromAvatarChange =
      this.pubSubService.onChangeAvatarSubjectEvent.subscribe((data) => {
        this.checkImg();
      });

    this.subsFromCollapseMenu =
      this.pubSubService.onCollapsedSidebarSubjectEvent.subscribe((data) => {
        this.isCollapsedSidebar = this.checkIsCollapsed();
      });

    this.isAdmin = this.authService.isAdmin();
    this.isEnt = this.authService.isEnt();

    CacheAll.setCookie(
      CacheConst.SIDEBAR_COLLAPSED,
      "non-collapsed",
      TTL.TOKEN
    );
  }
  
  getMainRouterURL() {
    let activeURL = "";
    let urlOrigin = location.pathname;
    let urlArr = urlOrigin.split('/');
    if (urlArr.length >= 3) {
      activeURL = `/${urlArr[1]}/${urlArr[2]}`;
    } else {
      activeURL = urlOrigin;
    }
    return activeURL;
  }

  ngOnInit() {
    this.getAllObjClasses();
    this.activeUrl = this.getMainRouterURL();
    this.checkImg();
    this.getUsername();
    this.initSearchMenu("searchMenuInput");
    this.totalNotifications = 0;
    this.getNotificationLog();
    this.notificationInterval = setInterval(() => {
      this.getNotificationLog();
    }, 15000);
    this.setHomeUrl();
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.subsFromAvatarChange.unsubscribe();
    this.subsFromCollapseMenu.unsubscribe();
    if (this.notificationInterval) clearInterval(this.notificationInterval);
  }

  setHomeUrl() {
    if (this.authService.isEnt())
      this.homeUrl = `/${SystemRoutingEnum.ENT_ROLE_HOME}`;
    else this.homeUrl = `/${SystemRoutingEnum.HOME}`;
  }

  initSidebarData() {
    // this.sidebarEntData = {
    //   name: "Báo cáo doanh nghiệp",
    //   routerLink: `/${SystemRoutingEnum.BUSINESS_REPORT}`,
    //   icon: "mdi mdi-table-edit",
    //   isExpand: false,
    //   isAdmin: false,
    //   child: [],
    // };
    // if (this.authService.isEnt()) 
    //   this.sidebarData = [
    //     {
    //       name: "Báo cáo doanh nghiệp",
    //       routerLink: `/${SystemRoutingEnum.BUSINESS_REPORT}`,
    //       icon: "mdi mdi-table-edit",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [],
    //     },
    //     {
    //       name: "Cung cấp thông tin",
    //       routerLink: `/${SystemRoutingEnum.REPORT_FORM}/provision`,
    //       icon: "mdi-file-document",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [],
    //     },
    //   ];
    // else 
    //   this.sidebarData = [
    //     {
    //       name: "Quản trị hệ thống",
    //       routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}`,
    //       icon: "mdi-cogs",
    //       isExpand: false,
    //       isAdmin: true,
    //       child: [
    //         {
    //           name: "Quản trị người dùng",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/user`,
    //         },
    //         {
    //           name: "Quản trị token",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/token`,
    //         },
    //         {
    //           name: "Quản trị doanh nghiệp",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/enterprise`,
    //         },
    //         {
    //           name: "Phân quyền",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/permission`,
    //         },
    //         {
    //           name: "Cấu hình nhóm dữ liệu",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/group-layer`,
    //         },
    //         {
    //           name: "Cấu hình quy trình nghiệp vụ",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/business-process-config`,
    //         },
    //         {
    //           name: "Cấu hình xử lý nghiệp vụ",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/business-process-permission`,
    //         },
    //         {
    //           name: "Cấu hình hệ thống mail",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/mail`,
    //         },
    //         {
    //           name: "Cấu hình tiêu chí",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/criteria-config`,
    //         },
    //         {
    //           name: "Cấu hình thuộc tính",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/field-config`,
    //         },
    //         {
    //           name: "Cấu hình lịch công tác",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/schedule`,
    //         },
    //         {
    //           name: "Cấu hình đồng bộ",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/sync-config`,
    //         },
    //         {
    //           name: "Cấu hình sao lưu",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/backup`,
    //         },
    //         {
    //           name: "Nhật ký",
    //           routerLink: `/${SystemRoutingEnum.SYSTEM_MANAGEMENT}/log/system`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Danh mục", //"sidebar-menu-shared-category",
    //       routerLink: `/${SystemRoutingEnum.CATEGORY}`,
    //       icon: "mdi-format-list-bulleted",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Danh mục dùng chung",
    //           routerLink: `/${SystemRoutingEnum.CATEGORY}/shared`,
    //         },
    //         {
    //           name: "Danh mục ngành công thương",
    //           routerLink: `/${SystemRoutingEnum.CATEGORY}/industry-and-trade`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Quản lý dữ liệu",
    //       routerLink: `/${SystemRoutingEnum.DATA_MANAGEMENT}`,
    //       icon: "mdi-layers-outline",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Khai thác dữ liệu",
    //           routerLink: `/${SystemRoutingEnum.DATA_MANAGEMENT}/maps`,
    //         },
    //         {
    //           name: "Quản lý dữ liệu mở rộng",
    //           routerLink: `/${SystemRoutingEnum.DATA_MANAGEMENT}/extended`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Thanh tra - kiểm tra",
    //       routerLink: `/${SystemRoutingEnum.INSPECTION}`,
    //       icon: "mdi-account-check-outline",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Quản lý kế hoạch",
    //           routerLink: `/${SystemRoutingEnum.INSPECTION}/plan`,
    //           isCheckPerm: {
    //             objClass: this.getObjClass("InspectionPlan"),
    //           },
    //         },
    //         {
    //           name: "Quản lý đoàn thanh tra",
    //           routerLink: `/${SystemRoutingEnum.INSPECTION}/team`,
    //           isCheckPerm: {
    //             objClass: this.getObjClass("InspectionPlan"),
    //           },
    //         },
    //         {
    //           name: "Quản lý Kết quả thanh kiểm tra",
    //           routerLink: `/${SystemRoutingEnum.INSPECTION}/management`,
    //           isCheckPerm: {
    //             objClass: this.getObjClass("Inspection"),
    //           },
    //         }
    //         // {
    //         //   name: "Dữ liệu",
    //         //   routerLink: `/${SystemRoutingEnum.INSPECTION}/data`,
    //         //   isCheckPerm: {
    //         //     objClass: this.getObjClass("Inspection"),
    //         //   },
    //         // },
    //       ],
    //     },
    //     {
    //       name: "Quản lý quy hoạch",
    //       routerLink: `/${SystemRoutingEnum.ZONING_MANAGEMENT}`,
    //       icon: "mdi-map",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Dữ liệu quy hoạch",
    //           routerLink: `/${SystemRoutingEnum.ZONING_MANAGEMENT}/data`,
    //         },
    //         {
    //           name: "Danh mục giai đoạn",
    //           routerLink: `/${SystemRoutingEnum.ZONING_MANAGEMENT}/stage`,
    //         },
    //         {
    //           name: "Tổng hợp thực hiện quy hoạch",
    //           routerLink: `/${SystemRoutingEnum.ZONING_MANAGEMENT}/planning-results`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Quản lý chỉ tiêu",
    //       routerLink: `/${SystemRoutingEnum.INDICATOR}`,
    //       icon: "mdi-gauge",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: this.translate.instant("sidebar-menu-indicators_planning"),
    //           routerLink: `/${SystemRoutingEnum.INDICATOR}/planning`,
    //         },
    //         {
    //           name: this.translate.instant("sidebar-menu-indicators"),
    //           routerLink: `/${SystemRoutingEnum.INDICATOR}/target`,
    //         },
    //         {
    //           name: this.translate.instant("sidebar-menu-indicators_valuetarget"),
    //           routerLink: `/${SystemRoutingEnum.INDICATOR}/value-target`,
    //         },
    //         {
    //           name: this.translate.instant("sidebar-menu-indicators_report"),
    //           routerLink: `/${SystemRoutingEnum.INDICATOR}/report`,
    //         },
    //         {
    //           name: this.translate.instant("sidebar-menu-indicators_icon"),
    //           routerLink: `/${SystemRoutingEnum.INDICATOR}/icon`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Xử lý nghiệp vụ", //"sidebar-menu-major_handling",
    //       routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}`,
    //       icon: "mdi-checkbox-multiple-marked-outline",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Quản lý quy trình",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/process-management`,
    //         },
    //         {
    //           name: "Tiêu chí thẩm định",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/criteria-management`,
    //         },
    //         {
    //           name: "Quản lý hồ sơ",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/m-document-management`,
    //         },
    //         {
    //           name: "Quản lý hồ sơ đã duyệt",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/a-document-approved`,
    //         },
    //         {
    //           name: "Quản lý dữ liệu cấp phép",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/certificate`,
    //         },
    //         {
    //           name: "E-Form",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/e-form`,
    //         },
    //         // {
    //         //   name: "Quản lý GCN",
    //         //   routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/certificate-management`,
    //         // }
    //         {
    //           name: "Công cụ tạo hồ sơ",
    //           routerLink: `/${SystemRoutingEnum.BUSINESS_PROCESS}/create-document-tool`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Báo cáo thống kê",
    //       routerLink: `/${SystemRoutingEnum.STATISTIC}`,
    //       icon: "mdi-chart-line",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Dữ liệu",
    //           routerLink: `/${SystemRoutingEnum.STATISTIC}/data`,
    //         },
    //         {
    //           name: "Hồ sơ - Giấy phép",
    //           routerLink: `/${SystemRoutingEnum.STATISTIC}/profile-license`,
    //         },
    //         {
    //           name: "Mẫu báo cáo",
    //           routerLink: `/${SystemRoutingEnum.STATISTIC}/sample`,
    //         },
    //         {
    //           name: "Thanh tra - Kiểm tra",
    //           routerLink: `/${SystemRoutingEnum.STATISTIC}/inspection`,
    //           isCheckPerm: {
    //             objClass: this.getObjClass("Inspection"),
    //           },
    //         }
    //       ],
    //     },
    //     {
    //       name: "sidebar-menu-business_report",
    //       routerLink: `/${SystemRoutingEnum.REPORT_FORM}`,
    //       icon: "mdi-file-document",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "sidebar-menu-major_simple_report_management",
    //           routerLink: `/${SystemRoutingEnum.REPORT_FORM}/management`,
    //         },
    //         {
    //           name: "sidebar-menu-major_simple_public_report_management",
    //           routerLink: `/${SystemRoutingEnum.REPORT_FORM}/publication`,
    //         },
    //         {
    //           name: "Cung cấp thông tin",
    //           routerLink: `/${SystemRoutingEnum.REPORT_FORM}/provision`,
    //         },
    //         {
    //           name: "Tổng hợp số liệu",
    //           routerLink: `/${SystemRoutingEnum.REPORT_FORM}/aggregation`,
    //         },
    //       ],
    //     },
    //     {
    //       name: "Chia sẻ dữ liệu",
    //       routerLink: `/${SystemRoutingEnum.DATA_SHARING}`,
    //       icon: "mdi-share-variant",
    //       isExpand: false,
    //       isAdmin: true,
    //       child: [],
    //     },
    //     {
    //       name: "Hồ sơ số hóa",
    //       routerLink: `/${SystemRoutingEnum.DIGITIZED_RECORD}`,
    //       icon: "mdi-file-multiple",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [],
    //     },
    //     {
    //       name: "Khảo sát thực địa",
    //       routerLink: `/${SystemRoutingEnum.FIELD_SURVEY}`,
    //       icon: "mdi-hiking",
    //       isExpand: false,
    //       isAdmin: false,
    //       child: [
    //         {
    //           name: "Kế hoạch khảo sát",
    //           routerLink: `/${SystemRoutingEnum.FIELD_SURVEY}/${FieldSurveyRoutingEnum.SURVEY_PLAN}`,
    //         },
    //         {
    //           name: "Lịch sử khảo sát",
    //           routerLink: `/${SystemRoutingEnum.FIELD_SURVEY}/${FieldSurveyRoutingEnum.SURVEY_HISTORY}`,
    //         },
    //       ],
    //     },
    //   ];
    // this.showMenuData = this.sidebarData; //showMenu use for Search
  }

  //#region Check Permission by ObjClass
  getAllObjClasses() {
    this.mainService.getListObjClass("", "asc", "name", "", 1).subscribe(
      (rs) => {
        let result = rs as ApiReturnModel;
        if (result.code == 0) {
          this.listObjClasses = result.data as ObjClassModel[];
          this.initSidebarData();
          this.getUserPBPermission();
        } else {
          if (!this.authService.checkInvalidResponse(result))
            this.toast.error(result.message);
        }
      },
      (error) => {
        this.toast.error(error.message);
      }
    );
  }

  getObjClass(code: string) {
    let oc = new ObjClassModel();
    for (let obj of this.listObjClasses) {
      if (code == obj.code) {
        oc = obj;
        break;
      }
    }
    return oc;
  }

  checkPermission(objClass: ObjClassModel) {
    return this.userPermService.checkPermission(
      objClass,
      this._permissionViewType
    );
  }
  //#endregion

  //#region User Action & Detail
  checkImg() {
    let imgUrl = CacheAll.getCookie(CacheConst.USER_IMAGE);
    this.avatarUrl = imgUrl;
    return this.avatarUrl.toLowerCase().match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  getUsername() {
    let json = CacheAll.getCookie(CacheConst.USER_DETAIL);
    if (json) {
      let userDetail = JSON.parse(
        Base64.decode(CacheAll.getCookie(CacheConst.USER_DETAIL))
      );
      this.userLastname = userDetail.lastName;
      this.userFirstname = userDetail.firstName;
    } else {
      this.userLastname = "H";
      this.userFirstname = "T";
    }
  }

  submitLogout() {
    //clear old cookie
    this.authService.logout().subscribe(
      (rs) => {
        let result = rs as ApiReturnModel;
        if (result.code != 0) {
          this.toast.error(result.message);
        }
      },
      (error) => {
        this.toast.error(error.message);
      }
    );
    CacheAll.deleteCookie(CacheConst.USER_TOKEN);
    CacheAll.deleteCookie(CacheConst.USER_ID);
    CacheAll.deleteCookie(CacheConst.USER_IMAGE);
    CacheAll.deleteCookie(CacheConst.USER_DETAIL);
    CacheAll.deleteCookie(CacheConst.USER_SESSION);
    this.routerService.navigate(["auth"], { queryParams: {} });
  }

  hiddenSidebar() {
    this.pubSubService.onSidebarClick();
  }

  checkIsCollapsed() {
    let bool = CacheAll.getCookie(CacheConst.SIDEBAR_COLLAPSED);
    if (bool === "collapsed") return true;
    else if (bool === "non-collapsed") return false;
    else return false;
  }
  //#endregion

  //#region Search Menu
  // searchMenuLocal(str: string) {
  //   str = str.toLowerCase();
  //   let returnData = [] as SidebarParent[];
  //   for (let data of this.sidebarData) {
  //     if (data.name.toLowerCase().includes(str)) returnData.push(data);
  //     else if (data.child.length > 0) {
  //       for (let child of data.child) {
  //         if (child.name.toLowerCase().includes(str)) {
  //           data.isExpand = true;
  //           returnData.push(data);
  //           break;
  //         }
  //       }
  //     }
  //   }
  //   return returnData;
  // }

  searchMenuLocal() {
    const searchKey = this.searchKey.toLowerCase();
    const returnData = [] as SidebarParent[];
    for (let data of this._showMenuData) {
      if (data.name.toLowerCase().includes(searchKey)) returnData.push(data);
      else if (data.child.length > 0) {
        for (let child of data.child) {
          if (child.name.toLowerCase().includes(searchKey)) {
            data.isExpand = true;
            returnData.push(data);
            break;
          }
        }
      }
    }
    return returnData;
  }

  search($event) {
    this.searchKey = $event.search_string;
    // this.showMenuData = this.searchMenuLocal(this.searchKey);
  }

  initSearchMenu(divId) {
    let searchDom = document.getElementById(divId) as HTMLInputElement;
    searchDom.addEventListener(
      "keydown",
      this.delay(function (e) {
        this.searchKey = searchDom.value;
        // if (searchDom.value) {
        //   this.showMenuData = this.searchMenuLocal(searchDom.value);
        //   return;
        // } else {
        //   this.showMenuData = this.sidebarData;
        // }
      }, 300).bind(this)
    );
  }

  delay(callback, ms) {
    var timer;
    return function () {
      var context = this,
        args = arguments;
      clearTimeout(timer);
      timer = setTimeout(function () {
        callback.apply(context, args);
      }, ms || 0);
    };
  }

  //#endregion

  //#region for Business Process Step
  getUserPBPermission() {
    this.userPermService.getCurrentUserPBPermissionByUserId().subscribe(
      (rs) => {
        let result = rs as ApiReturnModel;
        if (result.code == 0) {
          this.userBPPermissionData = result.data as UserBPPermissionModel[];
          for (let data of this.sidebarData) {
            if (data.routerLink == "/major-handling") {
              for (let bpPerm of this.userBPPermissionData) {
                let routing = bpPerm.baseStep
                  ? bpPerm.baseStep.code.toLowerCase().replace("_", "-")
                  : "";
                if (routing)
                  data.child.push({
                    name: bpPerm.baseStep.name,
                    routerLink: "/major-handling/" + routing,
                  });
              }
            }
          }
        } else {
          if (!this.authService.checkInvalidResponse(result))
            this.toast.error(result.message);
        }
      },
      (error) => {
        this.toast.error(error.message);
      }
    );
  }
  //#endregion

  //#region Notification
  getNotificationLog() {
    this.notificationService
      .getListPagingCurrentUserNotificationLog(1, "0")
      .subscribe(
        (rs) => {
          let result = rs as ApiReturnModel;
          if (result.code == 0) {
            let data = result.data as ListPagingModel;
            this.totalNotifications = data.totalElements;
          } else {
            if (!this.authService.checkInvalidResponse(result))
              this.toast.error(result.message);
          }
        },
        (error) => {
          this.toast.error(error.message);
        }
      );
  }

  formatNumberOfNotification(numberOfNotifications: number): string {
    let showNum = "";
    if (numberOfNotifications <= 100) showNum = numberOfNotifications + "";
    else showNum = "100+";
    return showNum;
  }

  openNotificationCenter() {
    this._notificationCenter.open();
  }

  onReadNotification() {
    this.getNotificationLog();
  }
  //#endregion
}
