<ng-template #notificationCenterModal let-modal>
    <div class="modal-header bg-white">
        <h5 class="modal-title">{{'notification-center-title' | translate}}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    <div class="modal-body bg-white p-4">
        <div class="col-12 text-right">
            <span class="badge---badge-success"><strong class="font-8">{{'global_amount' | translate }} : {{totalNotifications}}</strong></span>
        </div>       
        <div class="mt-1" *ngIf="totalNotifications > 0">
            <ng-container *ngFor="let item of showingNotification">
                <div class="row mb-2 border-row">
                    <div class="col-1 p-0 my-auto">
                        <em *ngIf="item.read === 0" class="mdi mdi-bell-ring mdi-24px text-warning"></em>
                        <em *ngIf="item.read === 1" class="mdi mdi-bell mdi-24px text-secondary"></em>
                    </div>
                    <div class="col-11 p-0">
                        <div class="row pt-0 pb-0">
                            <div class="col-9 p-0">
                                <p class="title">{{item.title}}</p>
                            </div>
                            <div class="col-3 p-0 text-right">
                                <button class="btn btn-primary btn-read" *ngIf="item.read === 0" (click)="submitRead(item)"><em class="mdi mdi-bell-plus-outline"></em > {{'notification-center-have_not_read' | translate}}</button>
                                <button class="btn btn-secondary btn-read" *ngIf="item.read === 1" [disabled]=true><em class="mdi mdi-bell-remove-outline"></em > {{'notification-center-have_read' | translate}}</button>
                            </div>
                        </div>
                        <p class="message">{{item.message}}</p>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="mt-1" *ngIf="totalNotifications == 0">
            <em class="not-found-content">{{'global_not-found' | translate}}</em>
        </div>  
    </div>
    <div class="modal-bottom bg-white">
        <div class="mt-1" *ngIf="totalNotifications > 0">
            <ngb-pagination 
                [(page)]="page" (pageChange)="changePage($event)"
                [pageSize]=5 [collectionSize]="totalNotifications" [boundaryLinks]="true" [maxSize]="5" [rotate]="true" [ellipses]="false"
                aria-label="Default pagination" class="pt-3 pagination-rounded d-flex justify-content-end">
            </ngb-pagination> 
        </div>  
    </div>
</ng-template>